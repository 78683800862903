import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const POST_TITLES = [
  'उच्चतर प्राध्यापकांसाठी अभ्यास कसा करावा?',
  'विद्यार्थ्यांसाठी परीक्षेत यश मिळवण्यासाठी उपयुक्त टिप्स',
  'परीक्षेत प्रफुल्लित व्हाव्यासाठी काय करावं?',
  'परीक्षेच्या संधीकाळात काय करावं?',
  'शैक्षणिक परीक्षेत व्हायरल होणारे त्रुटी कसे टाळावे?',
  'परीक्षेत निराशा वाटते, यासाठी काय करावं?',
  'अभ्यास करताना प्रगती कसी करावी?',
  // 'परीक्षेच्या संदर्भात स्वतंत्रपणे विचार कसे व्यक्त करावे',
  // '40 Free Serif Fonts for Digital Designers',
  // 'Examining the Evolution of the Typical Web Design Client',
  // 'Katie Griffin loves making that homey art',
  // 'The American Dream retold through mid-century railroad graphics',
  // 'Illustration System Design',
  // 'CarZio-Delivery Driver App SignIn/SignUp',
  // 'How to create a client-serverless Jamstack app using Netlify, Gatsby and Fauna',
  // 'Tylko Organise effortlessly -3D & Motion Design',
  // 'RAYO ?? A expanded visual arts festival identity',
  // 'Anthony Burrill and Wired mag’s Andrew Diprose discuss how they made January’s Change Everything cover',
  // 'Inside the Mind of Samuel Day',
  // 'Portfolio Review: Is This Portfolio Too Creative?',
  // 'Akkers van Margraten',
  // 'Gradient Ticket icon',
  // 'Here’s a Dyson motorcycle concept that doesn’t ‘suck’!',
  // 'How to Animate a SVG with border-image',
];

const posts = [...Array(7)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: `/assets/images/covers/cover_${index + 1}.jpg`,
  title: POST_TITLES[index + 1],
  createdAt: faker.date.past(),
  view: faker.datatype.number(),
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
  author: {
    name: faker.name.fullName(),
    avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  },
}));

export default posts;
